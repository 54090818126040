<template>
    <div class="fan-analysis">
        <div class="analysis-left">
            <div class="gender-distribution">
                <span class="title">性别分布</span>
                <div class="distribution-chart">
                    <div class="chart-icon">
                        <i class="iconfont">&#xe6a6;</i>
                        <i class="iconfont">&#xe6a8;</i>
                    </div>
                    <div class="chart-content">
                        <div class="man" :style="{width: man+'%'}"></div>
                        <div class="woman"></div>
                    </div>
                    <div class="legend">
                        <div class="legend-man">
                            <span>男</span>
                            <span>{{man}}%</span>
                        </div>
                        <div class="legend-woman">
                            <span>女</span>
                            <span>{{woman}}%</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="age-distribution">
                <span class="title">年龄分布</span>
                <div id="ageDistribution" style="width: 100%;height: 275px;position: relative"></div>
            </div>
        </div>
        <div class="analysis-right">
            <span class="title">地区分布</span>
            <div class="area-distribution">
                <div class="title">
                    <span>名称</span>
                    <span>占比</span>
                </div>
                <div class="content">
                    <div v-if="areaData.length > 0">
                        <div class="area-item" v-for="item in areaData">
                            <span class="area-name">{{item.areaName}}</span>
                            <el-progress :percentage="item.percentage" :show-text="false":stroke-width="12"></el-progress>
                            <span class="percentage" style="width: 50px">{{item.value}}%</span>
                        </div>
                    </div>
                    <div v-else class="null-data-area">暂无数据</div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "CompanyFanDataModule",
        data() {
            return {
                man: 0,
                woman: 0,
                age: [],
                areaData: []
            }
        },
        mounted() {
            this.ageDistribution = this.$echarts.init(document.getElementById('ageDistribution'));
            window.onresize = () => {
                this.ageDistributionFun(this.age);
            };
            let id = this.$route.query.id;
            if (id) {
                let param = {
                    platform_type: 1,
                    type:1,
                    id: id
                };
                this.$httpcompany.axiosGetBy(this.$api.get_account, param, res => {
                    if (res.code == 200) {
                        //男女比例
                        //計算男女之和
                        let sum = parseInt(res.data.fans_data.gender_distributions[0].value) + parseInt(res.data.fans_data.gender_distributions[1].value);
                        if (res.data.fans_data.gender_distributions && res.data.fans_data.gender_distributions.length>0){
                            let genderData = res.data.fans_data.gender_distributions;
                            if (typeof(genderData) == 'string'){
                                //判断类型是否为字符串，如果是字符串转为JSON格式
                                genderData = JSON.parse(
                                    genderData
                                );
                            }
                            this.man = Math.round(genderData[0].value / sum * 10000) / 100.00;
                            this.woman = Math.round(genderData[1].value / sum * 10000) / 100.00;

                        }
                        // 年龄段
                        if (res.data.fans_data.age_distributions && res.data.fans_data.age_distributions.length>0){
                            let ageData = res.data.fans_data.age_distributions;
                            if (typeof(ageData) == 'string'){
                                //判断类型是否为字符串，如果是字符串转为JSON格式
                                ageData = JSON.parse(
                                    ageData
                                );
                            }
                            this.age = ageData
                        }

                        this.ageDistributionFun(this.age);
                        // this.areaDataFun(res.data.geographical_distributions);
                        // 地区分布
                        if (res.data.fans_data.geographical_distributions && res.data.fans_data.geographical_distributions.length>0){
                            let areaData = res.data.fans_data.geographical_distributions;
                            if (typeof(areaData) == 'string'){
                                //判断类型是否为字符串，如果是字符串转为JSON格式
                                areaData = JSON.parse(
                                    areaData
                                );
                            }
                            this.areaDataFun(areaData)
                        }

                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                        this.ageDistributionFun([]);
                    }
                }, (err) => {
                    this.ageDistributionFun([]);
                    console.log(err)
                })
            }
        },
        methods: {
            ageDistributionFun(array) {
                //年龄段数据处理
                if (document.getElementById('ageDistribution')){
                    let ageArray = [],
                        dataArray = [];
                    if (array.length>0){
                        for (let i in array) {
                            ageArray.push(array[i].item);
                            let obj = {
                                value: array[i].value,
                                name: array[i].item
                            };
                            dataArray.push(obj)
                        }
                    }
                    ageArray.sort();
                    this.ageDistribution = this.$echarts.init(document.getElementById('ageDistribution'));
                    this.ageDistribution.setOption({
                        color: {
                            type: 'linear',
                            x: 0.5,
                            y: 0.5,
                            r: 0.5,
                            colorStops: [{
                                offset: 1, color: '#25C495' // 0% 处的颜色
                            }, {
                                offset: 0, color: '#039167' // 100% 处的颜色
                            }],
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: ageArray,
                                axisTick: {
                                    alignWithLabel: true
                                },
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: "#ccc"
                                    }
                                }
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                axisLine:{       //y轴
                                    show:false
                                },
                                axisTick:{       //y轴刻度线
                                    show:false
                                },
                                splitLine: {     //网格线
                                    show: false
                                },
                                axisLabel:{
                                    formatter:function () {
                                        return
                                    }
                                }
                            }
                        ],
                        series: [
                            {
                                name: '',
                                type: 'bar',
                                barWidth: '30%',
                                itemStyle: {        //上方显示数值
                                    normal: {
                                        label: {
                                            show: true, //开启显示
                                            position: 'top', //在上方显示
                                            textStyle: { //数值样式
                                                color: '#ccc',
                                                fontSize: 16
                                            }
                                        }
                                    }
                                },
                                data: dataArray,
                            }
                        ]
                    },true);

                    if(dataArray.length==0){
                        let div1 = document.createElement("div");
                        div1.innerHTML = "暂无数据";
                        div1.style.position = "absolute";
                        div1.style.top = "100px";
                        div1.style.left = "50%";
                        document.querySelector("#ageDistribution").appendChild(div1)
                    }
                }

            },
            areaDataFun(area) {
                //地区数据处理
                let sum = 0;
                if (area.length > 0) {
                    for (let i in area) {
                        sum += parseInt(area[i].value);
                    }
                    // let arr = area.slice(0, 10);
                    for (let i in area) {
                        sum += parseInt(area[i].value);
                    }
                    let arr = [];
                    for (let i = 0; i < 10; i++) {
                        let obj = {
                            areaName: area[i].item,
                            value: Math.round(parseInt(area[i].value) / sum * 10000) / 100.00,
                        };
                        arr.push(obj)
                    }
                    //遍历数组，默认arr中的某一个元素为最大值，进行逐一比较
                    for (let i = 0; i < arr.length; i++) {
                        //外层循环一次，就拿arr[i] 和 内层循环 arr[j] 做对比
                        for (let j = i; j < arr.length; j++) {
                            if (arr[i].value < arr[j].value) {
                                //如果arr[j]大,就把此时的值赋值给最大值变量max
                                let max = arr[j];
                                arr[j] = arr[i];
                                arr[i] = max;
                            }
                        }
                    }

                    for (let i = 0; i < arr.length; i++) {
                        if (i === 0) {
                            arr[i]['percentage'] = 100;
                        } else {
                            arr[i]['percentage'] = Math.round(parseInt(arr[i].value) / parseInt(arr[0].value) * 10000) / 100.00;
                        }
                    }
                    this.areaData = arr;
                }
            }
        },

    }
</script>

<style scoped lang="scss">
    .fan-analysis {
        display: flex;
        justify-content: space-between;
        height: 100%;
        padding-bottom: 20px;
        .analysis-left {
            display: flex;
            flex-direction: column;
            width: 50%;
            height: 100%;
            border-right: 1px solid #EAEAEA;
            padding: 0 20px;
            .gender-distribution {
                display: flex;
                flex-direction: column;
                margin-bottom: 50px;
                .title {
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                }
                .distribution-chart {
                    .chart-icon {
                        display: flex;
                        justify-content: space-between;
                        color: #34aa7f;
                        margin-top: 10px;
                        & i {
                            font-size: 24px;
                        }
                    }
                    .chart-content {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        height: 20px;
                        margin-top: 10px;
                        .man {
                            background-color: #34aa7f;
                        }
                        .woman {
                            flex: 1;
                            background-color: #46dbab;
                        }
                    }
                    .legend {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                        display: flex;
                        justify-content: space-between;
                        margin-top: 20px;

                        .legend-man {
                            display: flex;
                            flex-direction: column;
                        }
                        .legend-woman {
                            display: flex;
                            flex-direction: column;
                        }
                    }
                }
            }
            .age-distribution {
                .title {
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                }
            }
        }
        .analysis-right {
            display: flex;
            flex-direction: column;
            width: 50%;
            height: 100%;
            padding: 0 20px;
            .title {
                display: flex;
                justify-content: space-between;
                margin-bottom: 15px;
                padding-right: 5px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
            }
            .content {
                flex: 1;
                overflow: auto;
                padding-right: 5px;
            }
            .area-distribution {
                flex: 1;
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                height: calc(100% - 66px);
                .area-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 15px;
                    .area-name {
                        width: 50px;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    ::v-deep .el-progress {
                        width: 80%;
                        padding: 0 10px;
                    }
                    ::v-deep .el-progress-bar__inner {
                        background: linear-gradient(45deg, #ffa72a, #f8b95f 98%);
                    }
                }
            }
        }
        .null-data-area{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 200px;
        }
    }
</style>