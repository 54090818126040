<template>
    <div class="details">
        <div class="back-btn">
            <el-button @click="goBack">返回</el-button>
        </div>
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="数据概况" name="first" lazy>
                <DataOverviewModule/>
            </el-tab-pane>
            <el-tab-pane label="粉丝数据分析" name="second" lazy>
                <FanDataModule/>
            </el-tab-pane>
            <el-tab-pane label="播主视频" name="third" lazy>
                <BroadcasterVideoModule/>
            </el-tab-pane>
            <!--<el-tab-pane label="电商数据分析" name="fourth" lazy><EcommerceDataModule /></el-tab-pane>-->
        </el-tabs>
        <MineModule/>
    </div>
</template>

<script>
    import DataOverviewModule from '@/components/taskCenter/DataOverviewModule'
    import MineModule from '@/components/taskCenter/MineModule.vue'
    import FanDataModule from '@/components/taskCenter/FanDataModule.vue'
    import BroadcasterVideoModule from '@/components/taskCenter/BroadcasterVideoModule.vue'

    export default {
        name: "DataDetails",
        components: {
            DataOverviewModule,
            MineModule,
            FanDataModule,
            BroadcasterVideoModule,
            // EcommerceDataModule,
        },
        data() {
            return {
                activeName: 'first',
                beforeUrl: '',
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.beforeUrl = from.path
            })
        },
        mounted() {
            // let name = localStorage.getItem('currentTab')
            // 判断是否存在currentTab，即tab页之前是否被点击切换到别的页面
            // if(name){
            //     this.activeName = name
            // }
        },
        methods: {
            goBack() {
                if (this.beforeUrl == '/company/taskcenter/publish') {
                    this.$router.push({
                        path: `${this.beforeUrl}`,
                        query: {
                            isBack: true
                        }
                    })
                } else {
                    this.$router.push(this.beforeUrl)
                }
            },
            handleClick(tab) {
                // 点击tab后触发事件，修改显示页面，将状态保存在localStorage里面
                localStorage.setItem('currentTab', tab.name)
            }
        }
    }
</script>

<style scoped lang="scss">
    .details {
        display: flex;
        justify-content: start;
        height: 100%;
        position: relative;

        ::v-deep .el-tabs {
            box-shadow: none;
            border: none;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
            background: transparent;
            flex: 1;
            width: 1%;
            min-height: 838px;
        }

        ::v-deep .el-tabs__header {
            border-bottom: none;
            margin: 0;
        }

        ::v-deep .el-tabs__content {
            flex: 1;
            background: #fff;
            padding-top: 20px;
        }

        .el-tab-pane {
            height: 100%;
        }

        ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
            background: #fff;
        }

        ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
            border-left: none;
        }

        ::v-deep .el-tabs__header .is-active {
            background: #fff;
            font-size: 16px;
            font-weight: 400;
            color: #0824ce;
            border-radius: 6px 6px 0 0;
        }

        ::v-deep .el-tabs__item {
            height: 52px;
            line-height: 52px;
        }

        ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
            border: none;
        }

        ::v-deep .el-tabs__item:hover {
            color: #0824ce;
            cursor: pointer;
        }

        .back-btn {
            position: absolute;
            right:380px;
            top:10px;
            z-index:1;

            ::v-deep .el-button {
                width: 80px;
                height: 32px;
                background: #4D5AFF;
                border-radius: 2px;
                color: #ffffff;
                line-height: 9px;
            }
        }
    }
</style>